//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import EntityCharts from "@/components/EntityCharts";
import CalendarMixin from "@/mixins/Calendar";
import MyShopsMixin from "@/mixins/MyShops";
import Products from "@/mixins/products";
import AppSelect from "@/components/AppSelect.vue";
import { getEntityCellsConfig } from "@/utils/enums";
import ProjectListTable from "@/components/Seo/ProjectListTable";
import ProductsQueriesReport from "@/components/Seo/ProductsQueriesReport";
// import ProjectCompetitorTable from "@/components/Seo/ProjectCompetitorTable";
import DataLoading from "@/components/Loading/DataLoading";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import ModalEditProject from "@/components/modals/ModalEditProject";
import Tooltip from "@/components/Tooltip";
import { dateRangeBoundaries, dateRangeFromDate, today } from "@/utils/dates";
import SeoWrapper from "@/components/Seo/Wrapper";
export default {
  mixins: [CalendarMixin, MyShopsMixin, Products],
  meta: {
    title: "Обзор показателей"
  },
  data() {
    let cells = getEntityCellsConfig("project");
    const customCells = {
      products: {
        color: cells.queries.color,
        // if for fix dynamic when the first cell is multi
        id: "total_products_with_pos",
        multi: [{
          id: "queries",
          title: cells.queries.title,
          unit: cells.queries.unit
        }, {
          id: "products",
          title: cells.products.title,
          unit: cells.products.unit
        }]
      }
    };
    delete cells.queries;
    cells = {
      ...cells,
      ...customCells
    };
    return {
      calendarInternal: null,
      calendar: this.$store.state.connections.calendar,
      cells,
      selectedProject: {},
      selectedTableType: {},
      yourProducts: [],
      otherProducts: [],
      selectedYourSku: {},
      selectedOtherSku: {},
      isEditOpen: false,
      deskDatesOrder: false,
      dataCollection: false,
      pageIsLoaded: true,
      projectCalendarBoundaries: [],
      editOptions: [{
        id: 0,
        title: "Название",
        name: "name"
      }, {
        id: 1,
        title: "Запросы",
        name: "queries"
      }, {
        id: 2,
        title: "Товары",
        name: "products"
      }
      // {
      //     id: 3,
      //     title: "Конкуренты",
      //     name: "competitors",
      // },
      ]
    };
  },

  async created() {
    await this.loadProjects();
    this.dataCollection = !this.currentProject.initial_ready;
    await this.loadProducts();
    await this.getCalendarBoundaries();
    this.setCalendar();
    this.selectedTableType = this.table_types[0];
    this.pageIsLoaded = false;
  },
  methods: {
    setCalendar() {
      var _this$projectCalendar;
      const lastAvailableDate = ((_this$projectCalendar = this.projectCalendarBoundaries) === null || _this$projectCalendar === void 0 ? void 0 : _this$projectCalendar[1]) || today();
      const monthRange = dateRangeFromDate(lastAvailableDate, 30);
      this.calendar = dateRangeBoundaries([monthRange[0], monthRange[1]], this.projectCalendarBoundaries);
    },
    async loadProjects() {
      await this.$store.dispatch("seo/getProjects", {});
      this.selectedProject = {
        id: this.currentProject.id,
        title: this.currentProject.name
      };
    },
    async loadProducts() {
      const yourProducts = await this.$store.dispatch("seo/getProjectProducts", {
        id: this.currentProject.id,
        own: 1
      });
      const otherProducts = await this.$store.dispatch("seo/getProjectProducts", {
        id: this.currentProject.id,
        own: 0
      });
      this.yourProducts = yourProducts.data;
      this.otherProducts = otherProducts.data;
      this.selectedYourSku = this.yourSkuItems[0];
      this.selectedOtherSku = this.otherSkuItems[0];
    },
    async loadOverview() {
      var _this$currentProject, _this$currentProject2, _this$currentProject3, _this$currentProject4, _this$currentProject5, _this$currentProject6, _this$currentProject7, _this$currentProject8;
      const dynamic = await this.$store.dispatch("seo/getProjectHistory", {
        date_from: this.reportSettings.date_from,
        date_to: this.reportSettings.date_to,
        projectId: (_this$currentProject = this.currentProject) === null || _this$currentProject === void 0 ? void 0 : _this$currentProject.id
      });
      const res = {
        queries: {
          total: (_this$currentProject2 = this.currentProject) === null || _this$currentProject2 === void 0 ? void 0 : _this$currentProject2.total_queries,
          dynamic: []
        },
        products: {
          total: (_this$currentProject3 = this.currentProject) === null || _this$currentProject3 === void 0 ? void 0 : _this$currentProject3.total_products,
          dynamic: []
        },
        top1000: {
          total: (_this$currentProject4 = this.currentProject) === null || _this$currentProject4 === void 0 ? void 0 : _this$currentProject4.top1000,
          dynamic: []
        },
        // total_products_with_pos: {
        //     total: this.currentProject?.total_products_with_pos,
        //     dynamic: [],
        // },
        avg_position: {
          total: (_this$currentProject5 = this.currentProject) === null || _this$currentProject5 === void 0 ? void 0 : _this$currentProject5.avg_position,
          dynamic: []
        },
        top4: {
          total: (_this$currentProject6 = this.currentProject) === null || _this$currentProject6 === void 0 ? void 0 : _this$currentProject6.top4,
          dynamic: []
        },
        top12: {
          total: (_this$currentProject7 = this.currentProject) === null || _this$currentProject7 === void 0 ? void 0 : _this$currentProject7.top12,
          dynamic: []
        },
        top100: {
          total: (_this$currentProject8 = this.currentProject) === null || _this$currentProject8 === void 0 ? void 0 : _this$currentProject8.top100,
          dynamic: []
        }
      };
      dynamic.data.reverse();
      Object.keys(res).forEach(key => {
        res[key].dynamic = dynamic.data.map(item => item[key]);
      });
      return res;
    },
    onChangeDatesOrder() {
      this.deskDatesOrder = !this.deskDatesOrder;
    },
    editProject() {
      //console.log("test");
    },
    closeSelect() {
      this.isEditOpen = false;
    },
    openSelect() {
      this.isEditOpen = true;
    },
    onEdit() {
      this.$refs.ModalEditProject.open({
        item: this.currentProject,
        onComplete() {
          this.$store.dispatch("seo/getProjects", {});
        }
      });
      // this.isEditOpen = !this.isEditOpen
    },

    selectItem(name) {
      // eslint-disable-line
      // console.log(name);
      this.closeSelect();
    },
    async getCalendarBoundaries() {
      const dates = await this.$store.dispatch("seo/getMonitoringQueriesBorderDates", {
        project_id: this.currentProject.id
      });
      this.projectCalendarBoundaries = [dates.data.date_from, dates.data.date_to];
    }
  },
  computed: {
    datesOrderText() {
      return this.deskDatesOrder ? "Прямой порядок дат" : "Обратный порядок дат";
    },
    table_types() {
      let types = [{
        id: 10,
        title: "По товарам"
      }, {
        id: 20,
        title: "Списком"
      }];
      // if (this.otherSkuItems.length > 0) {
      //     types = [
      //         ...types,
      //         {
      //             id: 30,
      //             title: "Сравнить с конкурентами",
      //         },
      //     ];
      // }
      return types;
    },
    yourSkuItems() {
      return this.yourProducts.map(item => {
        return {
          id: item.id,
          title: item.sku
        };
      });
    },
    otherSkuItems() {
      return this.otherProducts.map(item => {
        return {
          id: item.id,
          title: item.sku
        };
      });
    },
    projects() {
      return this.$store.state.seo.projects;
    },
    projects_selected_items() {
      return this.projects.map(item => {
        return {
          id: item.id,
          title: item.name
        };
      });
    },
    currentProject() {
      return this.projects.find(item => item.id === Number(this.$route.params.project_id));
    },
    reportSettings() {
      var _this$currentProject9;
      if (!this.calendar) {
        return null;
      }
      const dates = this.calendar.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      return {
        deskDatesOrder: this.deskDatesOrder,
        date_from: dates[0],
        date_to: dates[1],
        project: this.currentProject,
        project_id: (_this$currentProject9 = this.currentProject) === null || _this$currentProject9 === void 0 ? void 0 : _this$currentProject9.id,
        mode: this.selectedTableType.id,
        own: this.selectedTableType.id === 30 ? 0 : 1
      };
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("connections/updateCalendar", this.calendar);
    },
    selectedProject() {
      this.$router.push({
        name: "ProjectInfo",
        params: {
          project_id: this.selectedProject.id
        }
      });
    },
    async currentProject() {
      await this.loadProducts();
      this.selectedProject = {
        id: this.currentProject.id,
        title: this.currentProject.name
      };
      await this.getCalendarBoundaries();
      this.selectedTableType = this.table_types[0];
      this.setCalendar();
    }
  },
  components: {
    MainTitle,
    EntityCharts,
    ReportPage,
    AppSelect,
    ProjectListTable,
    // ProjectByProductsTable,
    // ProjectCompetitorTable,
    DataTableActions,
    ModalEditProject,
    Tooltip,
    ProductsQueriesReport,
    DataLoading,
    SeoWrapper
  }
};